<template>
  <div class="tile is-parent block">
    <article class="tile is-child box">
      <div class="container">
        <iconComponent title="Mes prochains services" icone="fa-calendar" color="#ff4301" bgcolor="#ffd6c8"></iconComponent>
        <hr class="solid">
        <div v-for="service in filteredServices" v-bind:key="service.id">
          <div class="columns is-multiline">
            <div class="column is-8 is-12-touch">
              <p class="subtitle is-size-8">{{ formatDate(service.dateBegin) }}</p>
            </div>
            <div class="column is-12-touch">
              <span
                class="coordo has-text-primary is-uppercase is-size-7"
                v-on:click="selectedService=activateButton(service)"
                :class="{active: selectedService === service.id}"
              >Contact Coordo</span>
            </div>
          </div>
          <div class="columns" v-if="service.id==selectedService">
            <div class="column">
              <span
                v-for="coordo in service.shift.shiftLeaders"
                v-bind:key="coordo.id"
                class="coordinateur"
              >
                {{ coordo.name }}
                <br />
                <a :href="'mailto:' + coordo.email">{{ coordo.email + ' - '}}</a>
                <a :href="'tel:' + coordo.phone">{{ coordo.phone }}</a>
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="m-t-25">
          <router-link to="/services">
            <button class="button is-primary button-rounded">
              <i class="fa fa-eye fa-lg" aria-hidden="true"></i>
              Voir tous mes services
            </button>
          </router-link>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import shiftServices from "@/services/shiftServices";
import date from "@/utils/formatDate";
const IconComponent = () => import("@/components/IconComponent");

export default {
  name: "ProchainsServices",
  data: function() {
    return {
      services: [],
      loadingAPI: true,
      errorAPI: false,
      mesinfos: "",
      selectedService: 0
    };
  },
  mixins: [date],
  mounted() {
    this.mesinfos = this.$store.getters.getUser;
    this.init();
  },
  computed: {
    filteredServices() {
      if (this.services.length > 0)
        return this.services.slice().reverse().filter(service => {
          return (
            Date.now() <= Date.parse(service.dateBegin)
          );
        });
        return null
    }
  },
  methods: {
    // Récupérer les services d'un user dont l'état est open
    init: function() {
      shiftServices
        .fetchMyNextShifts(this.mesinfos.id)
        .then(
          response =>
            {this.services = response.data.shiftRegistrations.reverse(); }
        )
        .catch(() => {
          //console.log(error);
          this.errorAPI = true;
        });
    },

    activateButton: function(service) {
      return this.selectedService === service.id ? "" : service.id;
    }
  },
  components: {
    IconComponent,
  }
};
</script>

<style lang="css" scoped>
.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.coordinateur {
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
}

button:focus {
  outline: 0;
}

.coordo {
  cursor: pointer;
}
.block {
  flex: 1 1 0px;
  margin-bottom: 0;
}
</style>
